import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Contacts, Store} from '../shared/classes/store';
import { UserContact, UserContactSettings } from '../shared/classes/user-contact';
import { contactBannerFooter } from '../shared/data/contact-data-config';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  storeUri: string;
  logoUrl: string;
  storeColor: string;
  storeName: string;
  storeNameColor: string;
  headerColor: string;
  public contactData: Contacts;
  public contactFooterStg: UserContactSettings = contactBannerFooter;


  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe((res: { data: { storeUri: string }, store: Store }) => {
      this.storeUri = res.data.storeUri;
      this.logoUrl = res.store.settings.logoUrl;
      this.storeColor = res.store.settings.backgroundColor;
      this.storeName = res.store.settings.storeName;
      this.storeNameColor = res.store.settings.storeNameColor;
      this.headerColor = res.store.settings.headerColor;
      this.contactData = res.store.settings.contacts;
    });
  }

  ngOnInit(): void {
  }
}
