<div class="row justify-content-center" *ngIf="type === 'header'">
  <app-item-contact [type]="'email'" [linkClass]="settings?.email.class" [value]="contacts.email"
    [msg]="settings?.email?.msg" [iconClass]="'iconHeader'"></app-item-contact>

  <app-item-contact [type]="'whatsapp'" [linkClass]="settings.phone.class" [value]="contacts.telephone"
    [msg]="settings.phone?.msg" [iconClass]="'iconHeader'"></app-item-contact>
</div>

<div class="container-footer-contact" *ngIf="type === 'footer'">
  <app-item-contact [type]="'instagram'" [linkClass]="settings.instagram.class" [value]="contacts.instagram"
    [msg]="settings.instagram?.msg" [iconClass]="'iconHeader'"
    [iconColor]="settings.instagram.iconColor"></app-item-contact>
  <app-item-contact [type]="'facebook'" [linkClass]="settings.facebook.class" [value]="contacts.facebook"
    [msg]="settings.facebook?.msg" [iconClass]="'iconHeader'"
    [iconColor]="settings.facebook.iconColor"></app-item-contact>
  <app-item-contact [type]="'tik-tok'" [linkClass]="settings.tiktok" [value]="contacts.tiktok"
    [msg]="settings.tiktok?.msg" [iconClass]="'iconHeader'" [iconColor]="settings.tiktok.iconColor"></app-item-contact>
</div>

<div class="row justify-content-center" *ngIf="type === 'all'">
  <app-item-contact [type]="'email'" [linkClass]="settings?.email.class" [value]="contacts.email"
    [msg]="settings?.email?.msg" [iconClass]="'iconHeader'" [iconColor]="settings?.email.iconColor"></app-item-contact>
  <app-item-contact [type]="'whatsapp'" [linkClass]="settings.phone.class" [value]="contacts.telephone"
    [msg]="settings.phone?.msg" [iconClass]="'iconHeader'" [iconColor]="settings.phone.iconColor"></app-item-contact>
  <app-item-contact [type]="'instagram'" [linkClass]="settings.instagram.class" [value]="contacts.instagram"
    [msg]="settings.instagram?.msg" [iconClass]="'iconHeader'"
    [iconColor]="settings.instagram.iconColor"></app-item-contact>
  <app-item-contact [type]="'facebook'" [linkClass]="settings.facebook.class" [value]="contacts.facebook"
    [msg]="settings.facebook?.msg" [iconClass]="'iconHeader'"
    [iconColor]="settings.facebook.iconColor"></app-item-contact>
  <app-item-contact [type]="'tik-tok'" [linkClass]="settings.tiktok" [value]="contacts.tiktok"
    [msg]="settings.tiktok?.msg" [iconClass]="'iconHeader'" [iconColor]="settings.tiktok.iconColor"></app-item-contact>
</div>