import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../shared/services/store.service';
import {Settings, Store, Catalog} from '../../shared/classes/store';
import { UserContact, UserContactSettings } from 'src/app/shared/classes/user-contact';
import { HomeContactFooter, HomeContactStg, contactBannerFooter } from 'src/app/shared/data/contact-data-config';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  stores: Store[];
  contactDataBeeSeller: UserContact = HomeContactFooter;
  contactStg: UserContactSettings = HomeContactStg;

  constructor(private storeServices: StoreService) {
    this.stores = [];
  }

  ngOnInit(): void {
    const subscription = this.storeServices.getStores().subscribe((res: Store[]) => {
      this.stores = res.sort((a, b) => a.vendorCountry.localeCompare(b.vendorCountry));
      subscription.unsubscribe();
    });
  }

}
