import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StoreService} from '../services/store.service';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  constructor(private storeService: StoreService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const country = this.storeService.store?.vendorCountry;
    const req = request.clone({
      setHeaders: {
        'Store-Country': `${country}`
      }
    });

    return next.handle(req);
  }
}
