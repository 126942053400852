import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html'
})
export class CounterComponent implements OnInit {
    
    @Input() max: number = 0;
    counter: number = 0;

    @Output()
    public counterEvent: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {
        if (this.max) {
            this.counter = 1;
        }
    }

    decrement() {
        if (this.counter > 1) {
            this.counter--;
        }
        this.counterEvent.emit(this.counter);
    }

    increment() {
        if (this.counter < this.max) {
            this.counter++;
        }
        
        this.counterEvent.emit(this.counter);
    }
}