import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '../classes/store';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  public store: Store;
  private readonly storeBaseUrl: string;
  private readonly micrositeApiURL: string;
  private percentage: number;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.micrositeApiURL = `${baseUrl}/microsite/`;
    this.storeBaseUrl = this.micrositeApiURL + 'by-uri';
  }

  getLandingStore(uri: string): Observable<Store> {
    return this.http.get<Store>(`${this.storeBaseUrl}/${uri}`);
  }

  getStores(): Observable<Store[]> {
    return this.http.get<Store[]>(this.micrositeApiURL);
  }

  setStore(store: Store): void {
    this.store = store;

    localStorage.setItem('storeUri', store.uri);
    localStorage.setItem('storeUuid', store.uuid);
    localStorage.setItem('storeCountry', store.vendorCountry);
  }

  setPercentage(percentage: number) {
    this.percentage = percentage;
  }

  getPercentage(): number {
    return this.percentage;
  }
}
