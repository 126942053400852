import { Component, OnInit, Input } from '@angular/core';
import { UserContact, UserContactSettings } from '../classes/user-contact';
import { Contacts } from '../classes/store'
import { getCountry } from 'src/main';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() contactData: Contacts;
  @Input() contactStg: UserContactSettings;
  @Input() fullContact: boolean = false; 
  @Input() class: string = 'footer' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  country = getCountry();

  public today: number = Date.now();

  constructor() { }

  ngOnInit(): void {
  }

}
