import { UserContact, UserContactSettings } from "../classes/user-contact";

export const HomeContactFooter: UserContact = {
    email: "contacto@bee-seller.com",
    facebook: "profile.php?id=100075482276079",
    instagram: "beeseller__",
    phone: "59895977988",
    tiktok: "@beeseller",
}

export const contactBannerHeader: UserContactSettings = {
    iconColor: true,
    email: {
        class: 'contact',
        msg: 'Email'
    },
    phone: {
        class: 'contact',
        msg: 'Whatsapp'
    }
}

export const contactBannerFooter: UserContactSettings = {
    iconColor: false,
    instagram: {
        msg: '',
        class: '',
        iconColor: 'white',
    },
    facebook: {
        msg: '',
        class: '',
        iconColor: 'white'
    },
    tiktok: {
        msg: '',
        class: '',
        iconColor: 'white'
    }
}

export const HomeContactStg: UserContactSettings = { 
    ...contactBannerFooter,
    email: {
        msg: '',
        class: '',
        iconColor: 'white'
    },
    phone: {
        msg: '',
        class: '',
        iconColor: 'white'
    }
}