import {Injectable} from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import {CategoryService} from "./category.service";

@Injectable({
    providedIn: 'root'
})
export class CategoriesResolver implements Resolve<any> {

    constructor(private router: Router, private categoriesService: CategoryService) {
    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const categories = await this.categoriesService.getCategories().toPromise();
        return {categories: categories};
    }
}
