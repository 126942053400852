import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-item-contact',
    templateUrl: './item-contact.component.html',
    styleUrls: ['./item-contact.component.scss', '../../contact-banner.component.scss'],
})
export class ItemContactComponent implements OnInit {
    @Input() type: 'email' | 'facebook' | 'instagram' | 'whatsapp' | 'tik-tok';
    @Input() iconColor: string = 'black';
    @Input() iconClass: string = '';
    @Input() value: string;
    @Input() linkClass: string = '';
    @Input() msg: string = '';
    public href: string = '';
    public iconUrl: string = '';

    constructor() {}

    ngOnInit(): void {
        switch(this.type) {
            case 'facebook': {
                this.href = `https://www.facebook.com/${this.value}`;
                this.iconUrl = `assets/images/icon/SocialNet/facebook-${this.iconColor}.png`;
                return;
            };
            case 'instagram': {
                this.href = `https://www.instagram.com/${this.value}`;
                this.iconUrl = `assets/images/icon/SocialNet/instagram-${this.iconColor}.png`;
                return;
            };
            case 'whatsapp': {
                this.href = `https://wa.me/${this.value}`;
                this.iconUrl = `assets/images/icon/SocialNet/whatsapp-${this.iconColor}.png`;
                return;
            };
            case 'tik-tok': {
                this.href = `https://www.tiktok.com/@${this.value}`;
                this.iconUrl = `assets/images/icon/SocialNet/tik-tok-${this.iconColor}.png`;
                return;
            };
            default: {
                this.href = `mailto:${this.value}`;
                this.iconUrl = `assets/images/icon/SocialNet/email-${this.iconColor}.png`;
            };
        }
    }
}