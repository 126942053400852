import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ProductService } from '../services/product.service'
import { CategoryService } from '../services/category.service'
import { NavService } from '../services/nav.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() class: string
  @Input() themeLogo = 'assets/images/Bee-horizontal-black.png' // Default Logo
  @Input() topbar = true // Default True
  @Input() sticky = false // Default false
  @Input() storeUri: string
  @Input() storeColor: string
  @Input() storeName: string = ''
  @Input() storeNameColor: string = '#fff'
  public disabledSlideBar: boolean = false // Default false
  widthMobile = false

  public stick = false

  @ViewChild('inputField') inputField: ElementRef
  private inputSubject: Subject<string> = new Subject<string>()

  name = ''
  categoryId = ''

  @Output() onSearch: EventEmitter<boolean> = new EventEmitter<boolean>()

  categories = []

  constructor(
    private productsService: ProductService,
    private categoryService: CategoryService,
    private navService: NavService
  ) {
    this.onResize(window)
    this.inputSubject
      .pipe(
        debounceTime(1000), // espera 500ms después de que el usuario haya dejado de escribir
        distinctUntilChanged() // solo emite si el valor ha cambiado
      )
      .subscribe((value) => {
        // Aquí puedes hacer lo que necesites con el valor del input, por ejemplo, llamar a una función
        this.search(value)
      })

    this.categories = this.categoryService.categoriesByStore
    this.navService.leftMenuItems.next(this.categoryService.categoriesByStore)
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < 578) {
      this.widthMobile = true
      this.navService.leftMenuItems.next(this.categoryService.categoriesByStore)
    } else {
      this.widthMobile = false
    }
  }

  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const num =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    if (num >= 150 && window.innerWidth > 400) {
      this.stick = true
    } else {
      this.stick = false
    }
  }

  toggleSlideBar() {
    this.disabledSlideBar = !this.disabledSlideBar
  }

  onInput(event: any): void {
    const value = event.target.value
    this.inputSubject.next(value)
  }

  search(value: string): void {
    this.name = value
    // Aquí puedes realizar alguna acción con el valor del input
    this.formatJsonFilter()
  }

  searchCategory(categoryId: string): void {
    this.categoryId = categoryId
    // Aquí puedes realizar alguna acción con el valor del input
    this.formatJsonFilter()
  }

  formatJsonFilter() {
    this.productsService.filter = {
      name: this.name,
      categoryId: this.categoryId,
      priceOrder: this.productsService.filter?.priceOrder || 'NO',
    }
    this.onSearch.emit()
  }

  searchCategoryByMobile($event: number) {
    this.categoryId = $event === 0 ? '' : String($event)
    this.productsService.filter = {
      name: this.name,
      categoryId: this.categoryId,
      priceOrder: this.productsService.filter?.priceOrder || 'NO',
    }
    this.onSearch.emit()
  }
}
