<div *ngIf="!loader" class="product-box">
  <a
    *ngIf="!product?.currentStock"
    class=""
    [routerLink]="['/', storeUri, 'shop', 'product', product?.sku]"
  ></a>
  <div class="img-wrapper">
    <a [routerLink]="['/', storeUri, 'shop', 'product', product?.sku]">
      <img
        [src]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
        [lazyLoad]="ImageSrc ? ImageSrc : product.images[0]"
        class="img-fluid lazy-loading"
      />
    </a>
  </div>
  <div class="product-info">
    <div>
      <a [routerLink]="['/', storeUri, 'shop', 'product', product?.sku]" class="title">
        <h6>{{ product?.artNombreMl | titlecase }}</h6>
      </a>
      <h4>${{ this.getPrice(product?.pricePesos) }}</h4>
      <div class="container">
        <div class="buttons">
          <ng-container *ngIf="product?.currentStock > 0; else elseAddCartTemplate">
            <button
              class="btn btnAñadir"
              (click)="addToCart(product)"
              *ngIf="!cartModal"
              href="javascript:void(0)"
              title="Add to cart"
            >
              <span>{{ mobile ? ('Cart.add' | translate) : ('Cart.add-cart' | translate) }}</span>
            </button>
          </ng-container>
          <ng-template #elseAddCartTemplate>
            <div>
              <button class="btn btn-secondary btnOut mb-2" disabled>
                {{ 'Cart.spent' | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <app-quick-view #quickView [currency]="currency" [product]="product"></app-quick-view>
  <app-cart-modal
    #cartModal
    *ngIf="cartModal"
    [currency]="currency"
    [product]="product"
  ></app-cart-modal>
</div>
