import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

export function getUrl() {
  return environment.url
}

export function getBaseImgUrl() {
  return environment.baseImgUrl
}

export function getLanguage() {
  return environment.language
}

export function getCountry() {
  return environment.country
}

export function getKeyMP() {
  return environment.key_mp
}

export function getEnabledCoupuns() {
  return environment.enabledCoupuns
}

const providers = [
  { provide: 'BASE_URL', useFactory: getUrl, deps: [] },
  { provide: 'BASE_IMG_URL', useFactory: getBaseImgUrl, deps: [] },
]

if (environment.production) {
  enableProdMode()
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
})
