import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ProductService } from '../../services/product.service'
import { CartModalComponent } from '../modal/cart-modal/cart-modal.component'
import { QuickViewComponent } from '../modal/quick-view/quick-view.component'
import { Product } from '../../classes/product'

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product
  @Input() currency: any = this.productService.Currency // Default Currency
  @Input() thumbnail = false // Default False
  @Input() onHoverChangeImage = false // Default False
  @Input() cartModal = false // Default False
  @Input() loader = false
  @Input() public storeUri: string
  @Input() public btnColor: string
  @Input() public srcImage: string

  @ViewChild('quickView') QuickView: QuickViewComponent
  @ViewChild('cartModal') CartModal: CartModalComponent
  public ImageSrc: string
  public price: string

  mobile = false

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false
      }, 2000) // Skeleton Loader
    }

    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? true
      : false
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src
  }

  addToCart(product: any) {
    this.productService.addToCart(product)
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product)
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product)
  }

  getPrice(price: number): string {
    return this.productService.getEarningsValue(price).toFixed(2)
  }
}
