import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';

import { ElectronicsComponent } from './electronics/electronics.component';
// Widgest Components
import { SliderComponent } from './widgets/slider/slider.component';
import { LogoComponent } from './widgets/logo/logo.component';
import { ServicesComponent } from './widgets/services/services.component';
import { LandingComponent } from './landing/landing.component';
import { StoreBannerComponent } from './store-banner/store-banner.component';
import { PayDeliveryBannerComponent } from './pay-delivery-banner/pay-delivery-banner.component';

import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ElectronicsComponent,
    PayDeliveryBannerComponent,
    // Widgest Components
    SliderComponent,
    LogoComponent,
    ServicesComponent,
    LandingComponent,
    StoreBannerComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HomeModule { }
