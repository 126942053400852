<div
  [class.openSide]="productService?.OpenCart"
  [ngClass]="'right'"
  class="add_to_cart"
  id="cart_side"
>
  <a (click)="closeCart()" class="overlay" href="javascript:void(0)"></a>
  <div class="cart-inner">
    <div class="cart_top">
      <h3>{{ 'Cart.title' | translate }}</h3>
      <div (click)="closeCart()" class="close-cart">
        <a href="javascript:void(0)">
          <i aria-hidden="true" class="fa fa-times"></i>
        </a>
      </div>
    </div>
    <div *ngIf="products?.length" class="cart_media">
      <ul class="cart_product">
        <li *ngFor="let product of products">
          <div class="media">
            <a (click)="closeCart()" [routerLink]="['/', storeUri, 'shop', 'product', product.sku]">
              <img [src]="product.images[0]" class="mr-3" />
            </a>
            <div class="media-body">
              <a
                (click)="closeCart()"
                [routerLink]="['/', storeUri, 'shop', 'product', product.sku]"
              >
                <h4>{{ product?.artNombreMl | titlecase }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x
                  {{ this.productService.getEarningsValue(product?.pricePesos).toFixed(2) }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle">
            <a (click)="removeItem(product)" href="javascript:void(0)">
              <i aria-hidden="true" class="ti-trash"></i>
            </a>
          </div>
        </li>
      </ul>
      <ul class="cart_total">
        <li>
          <div class="total">
            <h5>
              subtotal :
              <span>$ {{ getTotal | async }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a
              (click)="closeCart()"
              [routerLink]="['/', storeUri, 'shop', 'cart']"
              class="btn btn-solid btn-xs view-cart"
              >{{ 'Cart.view-cart' | translate }}</a
            >
            <a
              *ngIf="locationCheckout"
              (click)="createOrder()"
              class="btn btn-solid btn-xs checkout"
              >{{ 'Cart.checkout' | translate }}</a
            >
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="!products?.length" class="col-sm-12 empty-cart-cls text-left">
      <h4 class="mt-3">
        <strong>{{ 'Cart.empty-title' | translate }}</strong>
      </h4>
      <a (click)="closeCart()" [routerLink]="'/' + storeUri" class="btn btn-solid">{{
        'Cart.return-store' | translate
      }}</a>
    </div>
  </div>
</div>
