import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-store-banner',
  templateUrl: './store-banner.component.html',
  styleUrls: ['./store-banner.component.scss']
})
export class StoreBannerComponent implements OnInit {

  @Input() bannerImg: string;
  @Input() bannerTitle: string;
  @Input() bannerSubtitle: string;
  @Input() bannerTitleColor: string;

  constructor() {
   }

  ngOnInit(): void {
  }

}
