<ul class="payment-container">
    <li *ngIf="ab" class="method">
        <img src="assets/images/icon/Payments/abitab(1).jpg" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="rp" class="method">
        <img src="assets/images/icon/Payments/redpagos2.jpeg" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="vi" class="method">
        <img src="assets/images/icon/Payments/visa1.png" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="mc" class="method">
        <img src="assets/images/icon/Payments/mastercard1.png" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="pay" class="method">
        <img src="assets/images/icon/Payments/paypal.png" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="oca" class="method">
        <img src="assets/images/icon/Payments/oca.png" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="ld" class="method">
        <img src="assets/images/icon/Payments/lider-logo.jpg" alt="" class="w-100 rounded mx-auto d-block">
    </li>
    <li *ngIf="dn" class="method">
        <img src="assets/images/icon/Payments/diners2.jpeg" alt="" class="w-100 rounded mx-auto d-block">
    </li>
</ul>