import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { Order } from 'src/app/shared/classes/order';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() product: Product;
  @Input() currency: any;

  @ViewChild("cartModal", { static: false }) CartModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public products: any[] = [];

  private subscriptions = new Subscription();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private productService: ProductService,
    private checkoutService: CheckoutService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  get storeCountry() {
    return localStorage.storeCountry.toUpperCase();
  }

  get location() {
    return location.pathname.match(/\/delivery/) || location.pathname.match(/\/payment/) ? 'hide' : 'show';
  }

  async openModal(product) {
    await this.productService.getProducts.subscribe(response => this.products = response);
    this.products = await this.products.filter(items => items.category == product.category && items.id != product.id);
    const status = await this.productService.addToCart(product);
    if (status) {
      this.modalOpen = true;
      if (isPlatformBrowser(this.platformId)) { // For SSR 
        this.modalService.open(this.CartModal, {
          size: 'lg',
          ariaLabelledBy: 'Cart-Modal',
          centered: true,
          windowClass: 'theme-modal cart-modal CartModal'
        }).result.then((result) => {
          `Result ${result}`
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public createOrder() {
    const order: Order = {
      storeUri: localStorage.storeUri, // TODO: Sacar atributo??
      storeUuid: localStorage.storeUuid,
      storeCountry: this.storeCountry, // TODO: Ver que hacer cuando el valor no esta seteado en el LocalStorage.
      orderProducts: [],
      discountCoupon: null,
      delivery: {
        address: '',
        department: '',
        location: '',
        doorNumber: '',
        type: '',
        postalCode: ''
      },
      shopper: {
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        document: ''
      },
    };

    this.products.forEach(product =>
      order.orderProducts.push({
        sku: product.sku,
        pricePesos: this.productService.getEarningsValue(product.pricePesos),
        quantity: product.quantity,
      })
    );

    this.subscriptions.add(this.checkoutService.createOrder(order));
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
